<!--edit form-->
<ng-template #templateEditAdd>
  <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna shadow-custom" [ngClass]="{'sticky-top-custom-modal': isInModal,
    'sticky-top-custom-modal-new': !_finanziamentoId,
    'sticky-top-custom':!isInModal  }">
    <div class=" row  justify-content-center">
      <div class="col-lg-2 col-md-3 text-center " *ngIf="activeTab==='tabDatiGenerali' && !readOnlyForm" [ngClass]="
            {
            ' offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show
            }
            ">
        <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary btn-block" type="button">
          Salva&nbsp;<i class="fas fa-save"></i>
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>


      <div class="text-center col-lg-2 col-md-4 " *ngIf="!isInInsertState">
        <button [disabled]="loading" type="button" class="btn btn-info btn-block bold" placement="bottom" delay="300"
          [popover]="popTemplate">
          Esporta&nbsp;<i class="fas fa-file-export"></i>
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
      <ng-template #popTemplate>
        <button class="btn btn-danger btn-block" type="button" (click)="esportaPdf()">
          PDF&nbsp;<i class="far fa-file-pdf"></i>

        </button>

        <button class="btn btn-success btn-block" type="button" (click)="esportaExcel()">
          EXCEL&nbsp;<i class="far fa-file-excel"></i>

        </button>
        <button class="btn btn-success btn-block" type="button" (click)="esportaExcelCompleto()">
          EXCEL COMPLETO&nbsp;<i class="far fa-file-excel"></i>

        </button>
      </ng-template>


      <!--<div class="text-center col-lg-2 col-md-4 " *ngIf="!isInInsertState" >
                <button
                [disabled]="loading" class="btn btn-info btn-block" type="button" (click)="esportaPdf()">
                   Crea PDF&nbsp;<i class="far fa-file-pdf"></i>
                   <img *ngIf="loading" class="pl-3 float-right"
                   src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </button>
            </div>-->



      <div class="col-lg-4 col-md-5 text-center ">
        <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
          class="btn btn-secondary btn-block font-weight-bold"
          type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}</button>
      </div>
    </div>
  </div>
  <div class="mb-5 ">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />
      <div class="col-lg-12 mt-5">

        <div class="form-group row" [hidden]="!editForm.controls.id.value">
          <div class="col-sm-12">
            <label for="codice">
              <h5 class="">Stato: {{statoFinanziamentoLabel}}</h5>
            </label>
          </div>
        </div>
        <div class="form-group row" [hidden]="!editForm.controls.id.value">
          <div class="col-sm-12">
            <label for="codice">
              <h5 class="">Codice:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="codice" maxlength="255" type="text" class="form-control"
              placeholder="Codice finanziamento" [ngClass]="{
                    'is-invalid': submitted && editForm.controls.codice.errors
                    }" />
            <div *ngIf="submitted && editForm.controls.codice.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="editForm.controls.codice.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <label for="pnrrRilevante">
              <h5 class="">Rilevante per PNRR:</h5>
            </label>
            <div>
              <div class="form-check  form-check-inline">

                <label for="pnrrRilevante1" class="form-check-label">
                  <input [formGroup]="editForm" id="pnrrRilevante1" formControlName="pnrrRilevante" type="radio"
                    value="1" class="form-check-input" [ngClass]="{
                                              'is-invalid': submitted && f.pnrrRilevante.errors
                                              }" (change)="pnrrRilevanteChange()" />Si
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label for="pnrrRilevante2" class="form-check-label">
                  <input [formGroup]="editForm" id="pnrrRilevante2" formControlName="pnrrRilevante" type="radio"
                    value="0" class="form-check-input" [ngClass]="{
                                                            'is-invalid': submitted && f.pnrrRilevante.errors
                                                            }" (change)="pnrrRilevanteChange()" />No
                </label>
              </div>
            </div>


            <div *ngIf="submitted && f.pnrrRilevante.errors" class="invalidFieldMessage" style="display: block;">
              <div *ngIf="f.pnrrRilevante.errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-5" [hidden]="!showPnrrLineeFinanziamento">
          <hr>
        </div>
        <div class="col-lg-12 mt-4 " [hidden]="!showPnrrLineeFinanziamento">
          <div class="row justify-content-between">

            <div class="col-lg-6 col-sm-6 col-6 mt-3">
              <h5 for="" class="">Linea finanziamento PNRR/PNC</h5>
            </div>
            <div
              [hidden]="readOnlyForm || validato ||(pnrrRilevante && hasProvvedimenti) "
              class="col-lg-1 col-sm-2 col-3 text-center mt-3">
              <a class="bottone-inserisci" (click)="openModalPnrrLineeFinanziamento();">
                <i class="fas fa-plus pointer"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-12 mt-3 table-responsive-lg " [hidden]="!showPnrrLineeFinanziamento">
          <table class="table table-hover table-colore">
            <thead class="">
              <th class="px-1">codice</th>
              <th class="px-1">missione</th>
              <th class="px-1">componente</th>
              <th class="px-1">descrizione</th>
              <th class="px-1">misura</th>
              <th class="px-1">tipologia</th>
              <th class="px-1">quota pnrr</th>
              <th class="px-1">quota pnc</th>
              <th [hidden]="readOnlyForm || validato ||(pnrrRilevante && hasProvvedimenti)"
                class="casella-icona-tx px-1">elimina</th>
            </thead>
            <tbody>
              <ng-container *ngIf="pnrrLineaFinanziamentoSelezionata">
                <tr>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.codice}}</td>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.missione.descrizione}}</td>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.componente.descrizione}}</td>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.descrizione}}</td>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.misuraPnrr}}</td>
                  <td class="small px-1">{{pnrrLineaFinanziamentoSelezionata.tipologia}}</td>
                  <td class="small px-1">{{printValueAsCurrency(pnrrLineaFinanziamentoSelezionata.pnrr)}}</td>
                  <td class="small px-1">{{printValueAsCurrency(pnrrLineaFinanziamentoSelezionata.fc)}}</td>
                  <td class="small px-1" [hidden]="readOnlyForm || validato ||(pnrrRilevante && hasProvvedimenti)"><a
                      (click)="removePnrrLineaFinanziamento()" href="javascript:;;" data-toggle="tooltip"
                      data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!(pnrrLineaFinanziamentoSelezionata)">
                <td colspan="8">Nessuna linea di finanziamento PNRR/PNC selezionata</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="submitted && pnrrLineaFinanziamentoIsError" class="invalidFieldMessage">
            <div class="invalid-feedback" style="display: block;">
              Selezionare almeno una linea di finanziamento PNRR/PNC
            </div>
          </div>
        </div>




        <div class=" row">
          <div class="form-group col-sm-12">
            <label for="titolo">
              <h5 class="mandatory">Titolo:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="titolo" type="text" class="form-control"
              placeholder="Inserisci il titolo" [ngClass]="{
                    'is-invalid': submitted && editForm.controls.titolo.errors
                    }" />
            <div *ngIf="submitted && editForm.controls.titolo.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="editForm.controls.titolo.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class=" row">
          <div class="form-group col-sm-6">
            <div class="d-flex justify-content-between">

              <label for="tipologia">
                <h5 class="mandatory">Fonte:</h5>
              </label>
              <div class="text-center " style="width:57.5px">
              <a href="javascript:;;" class="bottone-inserisci" (click)="openModalFonteFinanziamento($event)"><i
                class="fas fa-plus"></i></a>
                </div>
              </div>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls.tipologia.errors }">
              <ng-select [formGroup]="editForm" formControlName="tipologia" [items]="tipiFinanziamento" bindValue="id"
                bindLabel="tipoFinanziamento" placeholder="Seleziona una fonte" (change)="tipologiaChange($event)">
              </ng-select>
            </div>

            <div *ngIf="submitted && editForm.controls.tipologia.errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls.tipologia.errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <div class="d-flex justify-content-between">
              <label for="strumentoAttuativo">
                <h5 class="">Strumento attuativo:</h5>
              </label>
              <div class="text-center " style="width:57.5px">
              <a href="javascript:;;" class="bottone-inserisci" (click)="openModalStrumentoAttuativo($event)"><i
                  class="fas fa-plus"></i></a>
                  </div>
            </div>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls.strumentoAttuativo.errors }">
              <ng-select [formGroup]="editForm" formControlName="strumentoAttuativo" [items]="tipiStrumentiAttuativi"
                bindValue="id" bindLabel="descrizione" placeholder="Seleziona uno strumento attuativo"
                (change)="strumentoAttuativoChange($event);">
              </ng-select>
            </div>

            <div *ngIf="submitted && editForm.controls.strumentoAttuativo.errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls.strumentoAttuativo.errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="form-group col-sm-6">
            <label for="importoComplessivo">
              <h5 class="">Importo complessivo:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="importoComplessivo" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci l'importo complessivo" [ngClass]="{
                      'is-invalid': submitted && editForm.controls.importoComplessivo.errors
                      }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && editForm.controls.importoComplessivo.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.importoComplessivo.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <label for="disponibilitaResidua">
              <h5 class="">Disponibilità residua:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="disponibilitaResidua" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci la disponibilità residua" [ngClass]="{
                      'is-invalid': submitted && editForm.controls.disponibilitaResidua.errors
                      }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && editForm.controls.disponibilitaResidua.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.disponibilitaResidua.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <!--confinaziamento compare se solo se isconfinanziamento è true-->
        <div class="row">
          <div class="form-group col-sm-6" *ngIf="isCofinanziamento">
            <label for="percentualeCofinanziamento">
              <h5 class="mandatory">Cofinanziamento:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="percentualeCofinanziamento" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci la percentuale di cofinanziamento" [ngClass]="{
                        'is-invalid': submitted && editForm.controls.percentualeCofinanziamento.errors
                        }" [imask]="maskCofin" [unmask]="true" />
            <div *ngIf="submitted && editForm.controls.percentualeCofinanziamento.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.percentualeCofinanziamento.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">

            <label for="scadenza">
              <h5 class="mandatory">Scadenza:</h5>
            </label>

            <div [ngClass]="{
            'is-invalid': submitted && editForm.controls.scadenza.errors
            }" class="col-lg-12 col-md-6 col-sm-8 col-xs-7" style="display:flex; padding-right:0px;padding-left:0px">

              <input type="text" formControlName="scadenza" class="form-control" bsDatepicker [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #scadenza="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="scadenza.toggle()" type="button"
                [hidden]="editForm.controls && !editForm.controls.scadenza.enabled"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>

            <div *ngIf="submitted && editForm.controls.scadenza.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.scadenza.errors.required">
                Campo obbligatorio
              </div>
            </div>

          </div>




        </div>








      </div>
      <div class="col-lg-12 mt-5">
        <hr>
      </div>

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="mandatory">Aree gestionali direzione e/o esterne</h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3" [hidden]="readOnlyForm || validato">
            <a href="javascript:;;" class="bottone-inserisci" (click)="openModalAT();"><i class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th class="casella-descrizione-tx">descrizione</th>
            <th>codice ipa</th>
            <th>struttura</th>
            <th class="casella-icona-tx" [hidden]="readOnlyForm || validato">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="areeTematicheSelezionate &&  areeTematicheSelezionate.length>0">
              <tr *ngFor="let item of areeTematicheSelezionate">
                <td>{{item.disabled?"(disattiva) ":""}}{{item.descrizione}}</td>
                <td>{{item.codiceIpa}}</td>
                <td>{{item.struttura}}</td>
                <td [hidden]="readOnlyForm || validato"><a (click)="removeAreaTematica(item)" href="javascript:;;"
                    data-toggle="tooltip" data-placement="top" title="elimina"><i
                      class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(areeTematicheSelezionate &&  areeTematicheSelezionate.length>0)">
              <td colspan="4">Nessuna area direzione selezionata</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && !(areeTematicheSelezionate &&  areeTematicheSelezionate.length>0)"
          class="invalidFieldMessage">
          <div class="invalid-feedback" style="display: block;">
            Selezionare almeno un'area direzione
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-5">
        <hr>
      </div>


      <div class="col-lg-12 mt-5 mb-5">


        <div class=" row">
          <div class="form-group col-sm-12 mb-3">
            <label for="codice" class="">
              <h5>Note responsabile:</h5>
            </label>
            <textarea [formGroup]="editForm" formControlName="note" type="text" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && editForm.controls.note.errors
                  }" placeholder="Inserisci eventuali note" rows="3"></textarea>
            <div *ngIf="submitted &&  editForm.controls.note.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf=" editForm.controls.note.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>


</ng-template>

<!--modale-->
<ng-template #templateModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

  </div>
</ng-template>

<!--tabs-->




<div class="container">


  <div class="container-xl">
    <div class="pb-3">
      <div class=" row  ">
        <div class="titolo col-md-12 ">
          <h3 class="mt-3">{{modalTitle}}</h3>
        </div>
      </div>



    </div>
    <finanziamento-div-dettaglio-component class="row  justify-content-star"></finanziamento-div-dettaglio-component>
    <div class="row">
      <div id="tabs" #tabs class="col-lg-12">
        <ul class="nav nav-tabs navscheda position-sticky sticky-top   bg-white pt-4 pb-1"
          [ngClass]="{'top-tab-modal': isInModal,'top-tab-custom':!isInModal  }">
          <li class="nav-item mt-1 px-1">
            <a (click)=" confirmChangeTab($event,0);" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}"
              class="nav-link" href="#divTemplateEditAdd" data-toggle="tab">Dati generali</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || hideAnnualitaTab">
            <a (click)=" confirmChangeTab($event,2);" [ngClass]="{ 'active':activeTab==='tabAnnualita'}"
              class="nav-link" href="#divTemplateAnnualita" data-toggle="tab">Annualità</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,1);" [ngClass]="{ 'active':activeTab==='tabFasi'}" class="nav-link"
              href="#divTabFasiContent" data-toggle="tab">Fasi</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,12);" [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}"
              class="nav-link" href="#divProvvedimentiDocumenti" data-toggle="tab">Provvedimenti/Documentazione</a>
          </li>
          <!--<li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,3);" [ngClass]="{ 'active':activeTab==='tabProvvedimenti'}"
              class="nav-link" href="#divTabProvvedimentiContent" data-toggle="tab">Provvedimenti</a>
          </li>-->

          <!--<li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,4);" [ngClass]="{ 'active':activeTab==='tabDocumentazione'}"
              class="nav-link" href="#divTemplateAnnualita" data-toggle="tab">Documentazione</a>
          </li>-->
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,9);" [ngClass]="{ 'active':activeTab==='tabRiepilogo'}" class="nav-link"
              href="#divTemplateRiepilogo" data-toggle="tab">Riepilogo</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,10);" [ngClass]="{ 'active':activeTab==='tabStorico'}" class="nav-link"
              href="#divTemplateStorico" data-toggle="tab">Storico</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,11);" [ngClass]="{ 'active':activeTab==='tabUsatoInterventi'}"
              class="nav-link" href="#divTemplateUsatoInterventi" data-toggle="tab">Progettualità/Interventi</a>
          </li>

          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,13);" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}"
              class="nav-link" href="#divSicerBilancioContent" data-toggle="tab">SICER - Contabilità</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane " id="divTabFasiContent" [ngClass]="{ 'active':activeTab==='tabFasi'}">
            <fasi-component id="tabFasiContent" [isInModal]="isInModal"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)" [finanziamentoId]="finanziamentoId" #tabFasiContent>
            </fasi-component>
          </div>
          <div class="tab-pane " id="divTemplateEditAdd" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}">
            <ng-container id="templateEditAdd" *ngTemplateOutlet="templateEditAdd"></ng-container>
          </div>
          <div class="tab-pane " id="divTemplateAnnualita" [ngClass]="{ 'active':activeTab==='tabAnnualita'}">
            <associa-annualita-component [isInModal]="isInModal" #tabAnnualitaContent id="templateAnnualita"
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)" (onSubmitEmitter)="changedAnnualita($event)">
            </associa-annualita-component>
          </div>
          <!--<div class="tab-pane " id="divTabProvvedimentiContent" [ngClass]="{ 'active':activeTab==='tabProvvedimenti'}">
            <associa-provvedimenti-component [isInModal]="isInModal" #tabProvvedimentiContent id="templateProvvedimenti"
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)"
              (onChangeOk)="associaProvvedimentiChanged($event) ">
            </associa-provvedimenti-component>
          </div>-->
          <!--<div class="tab-pane " id="divTabDocumentazioneContent"
            [ngClass]="{ 'active':activeTab==='tabDocumentazione'}">
            <documentazione-component [isInModal]="isInModal" [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)">
            </documentazione-component>
          </div>-->
          <div class="tab-pane " id="divTemplateRiepilogo" [ngClass]="{ 'active':activeTab==='tabRiepilogo'}">
            <riepilogo-finanziamento-component [isInModal]="isInModal" #divTemplateRiepilogo
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)">
            </riepilogo-finanziamento-component>
          </div>
          <div class="tab-pane " id="divTemplateStorico" [ngClass]="{ 'active':activeTab==='tabStorico'}">
            <storico-finanziamento-component [isInModal]="isInModal" #divTemplateStorico
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)">
            </storico-finanziamento-component>
          </div>
          <div class="tab-pane " id="divTemplateUsatoInterventi"
            [ngClass]="{ 'active':activeTab==='tabUsatoInterventi'}">
            <finanziamento-usato-da-interventi-component [isInModal]="isInModal" #divTemplateUsatoInterventi
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)">
            </finanziamento-usato-da-interventi-component>
          </div>
          <div class="tab-pane " id="divProvvedimentiDocumenti"
            [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}">
            <associa-provvedimenti-documenti-component [isInModal]="isInModal" #divProvvedimentiDocumentiContent
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)"
              (associaProvvedimentiChangedEmitter)="associaProvvedimentiChanged($event) ">
            </associa-provvedimenti-documenti-component>
          </div>

          <div class="tab-pane " id="divSicerBilancioContent" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}">
            <sicer-bilancio-component [isInModal]="isInModal" #divSicerBilancioContent [type]="type"
              [finanziamentoId]="finanziamentoId"
              [gotoBtnOptions]="{show:true,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
              (gotoBtnFunction)="gotoBackBtnClick($event)">
            </sicer-bilancio-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateStrumentoAttutativoModal>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci strumento attuativo</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalStrumentoAttuativo()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="strumentoAttuativoForm" class="col-lg-12" (ngSubmit)="onSubmitStrumentoAttuativo()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />
      <div class="form-group">
        <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
        <input [formGroup]="strumentoAttuativoForm" formControlName="descrizione" maxlength="255" type="text" value=""
          class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{'form-control': true,
                'is-invalid': submitSt && s.descrizione.errors}" />
        <div *ngIf="submitSt && s.descrizione.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="s.descrizione.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group" [ngClass]="{
    'is-invalid': submitSt && s.cofinanziamento.errors
    }">
        <h5> <label for="" class="mandatory">Cofinanziamento:</label></h5>
        <div class="form-check form-check-inline ml-2">

          <input [formGroup]="strumentoAttuativoForm" id="cofinanziamento1" formControlName="cofinanziamento"
            type="radio" value="1" class="form-check-input"
            [ngClass]="{'is-invalid': submitSt && s.cofinanziamento.errors}" />

          <label for="cofinanziamento1" class="form-check-label">Si</label>
        </div>
        <div class="form-check form-check-inline">
          <input [formGroup]="strumentoAttuativoForm" id="cofinanziamento2" formControlName="cofinanziamento"
            type="radio" value="0" class="form-check-input"
            [ngClass]="{'is-invalid': submitSt && s.cofinanziamento.errors}" />
          <label for="cofinanziamento2" class="form-check-label">No</label>
        </div>
      </div>
      <div *ngIf="submitSt && s.cofinanziamento.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="s.cofinanziamento.errors.required">
          Campo obbligatorio
        </div>
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
        Aggiungi
      </button>
    </form>
  </div>
</ng-template>

<ng-template #templateModalFonteFinanziamento>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci fonte finanziamento</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalFonteFinanziamento()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="fonteFinanziamentoForm" (ngSubmit)="onSubmitFonteFinanziamento()">
      <div class="col-lg-12">
          <input type="hidden" [formGroup]="fonteFinanziamentoForm" formControlName="id" />
          <div class="form-group">
              <h5><label for="tipoFinanziamento" class="mandatory">Fonte finanziamento:</label></h5>
              <input [formGroup]="fonteFinanziamentoForm" formControlName="tipoFinanziamento" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la fonte di finanziamento" [ngClass]="{
                  'form-control': true,
                  'is-invalid': submitFonte && ff.tipoFinanziamento.errors
                  }" />
              <div *ngIf="submitFonte && ff.tipoFinanziamento.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="ff.tipoFinanziamento.errors.required">
                      Campo obbligatorio
                  </div>
              </div>
          </div>
      </div>


      <div class="col-lg-12 mb-3"><button class="btn btn-lg btn-primary btn-block" type="submit">
         Inserisci
      </button></div>
  </form>
  </div>
</ng-template>